.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.selected-video {
  border: 10px solid rgb(85, 212, 0);
}

.background-button {
  color: white;
  background: linear-gradient(54.09deg,#8aaf3f 2.03%,rgb(165 219 15) 48.63%,#7ad716 96.22%);
  min-height: 25vh;
}

.rs-btn-primary{
  background-color: #ff343d;
}

.bg-box {
  color: white;
  background: linear-gradient(54.09deg,#8aaf3f 2.03%,rgb(165 219 15) 48.63%,#7ad716 96.22%);
}

.app-link img {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.app-link img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.app-link img:hover::after {
  content: "Coming Soon";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.app-link img:hover::after:hover {
  opacity: 1;
}




.custom-button {
  background-color: black;
  color: white;
  width: 150px;
  height: 40px;
}

.custom-button:hover {
  background-color: rgb(177, 0, 0);
  color: black;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.columns-3xs {
  /* Set the maximum width of the container or adjust as needed */
  max-width: 100%;

  /* Center the container */
  margin-left: auto;
  margin-right: auto;

  /* Add padding or adjust as needed */
  padding-left: 1rem;
  padding-right: 1rem;
}

.columns-3xs-item {
  /* Adjust the width for extra-small screens */
  flex: 0 0 calc(33.33% - 1rem);

  /* Adjust margin or add other styling as needed */
  margin-bottom: 1rem;
}

/* Media query for small screens and up */
@media (min-width: 600px) {
  .columns-3xs-item {
    /* Adjust the width for small screens and larger */
    flex: 0 0 calc(33.33% - 1rem);
  }
}

/* Media query for medium screens and up */
@media (min-width: 768px) {
  .columns-3xs-item {
    /* Adjust the width for medium screens and larger */
    flex: 0 0 calc(33.33% - 1rem);
  }
}

/* Add more media queries for larger screen sizes if needed */


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
